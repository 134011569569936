import React from 'react'

import {Typography} from 'antd'

import {motion} from 'framer-motion'

import fastily from '../../assets/partners/fastily-logo.png'
import muvik from '../../assets/partners/muvik-logo.png'
import m2 from '../../assets/partners/m2-logo.png'
import bci from '../../assets/partners/openbci-logo.png'

const {Title, Text} = Typography

const Partners = () => {

    return(
        <div style={{width: "100%", alignItems: 'center', display: 'flex', flexDirection: "column", marginTop: '60px'}}>
        <Title level={2} style={{color: "#13384e", marginTop: "30px", textAlign: "center"}}> Mission </Title>
        <Text style={{margin:'0px auto', maxWidth: '800px', width: '90%'}}>Children with neurodevelopmental disorders such as autism spectrum disorder (ASD) are at a far higher risk for developing anxiety disorders. Many children with ASD perform poorly in school, and many others refuse to participate in the school system and ultimately disengage from formal learning. It is unconscionable that young people with ASD can perform poorly in school or even lose access to formal education entirely due to a condition they cannot control. Given the prevalence of anxiety as a chronic mental disorder and its role as a major obstacle for education for children with ASD, we aim to improve educational accessibility for students with autism and develop classroom-based interventions that can identify anxious states and allow for tailored and targeted anxiety mitigation.</Text>
        </div>
    )
}

export default Partners