import React from 'react'
import useMedia from '../../hooks/useMedia'

import {Card, Typography, Image} from 'antd'


import squiggle from '../../assets/pattern-bg/fancy-squiggle.svg'
import bg from '../../assets/pattern-bg/bg-image.png'

const {Text, Title, Paragraph} = Typography



const Mission = () => {
    const mobile = useMedia(['(min-width: 750px)', '(max-width: 750px)'], [false, true])

    return(
        <div style={{height: mobile ? "105vh" : "85vh", background: `url(${bg})`, backgroundSize: "cover",marginTop: "80px", position: 'relative' }}>
            <img src={squiggle} style={{transform: "scaleY(-1.1) scaleX(1.2)", width: "100%", position: 'absolute', top: "-1px", fill: "red"}}></img>

            <div style={{display: "flex", justifyContent:  mobile ? "center" : "flex-end", alignItems: 'center', height: "100%", padding: mobile ? "20px 0px": "0px 100px 0px 0px" }}>
                <Card style={{width: mobile ? "350px" : "550px", padding: mobile ? "20px 20px": "36px 50px",boxShadow: "2px 4px 10px rgba(0,0,0,0.1)", marginTop: "20px"}}>
                    <Title level={2} style={{color: "#13384e"}}> Our Mission. </Title>

                    <Paragraph style={{lineHeight: "1.9", textAlign: 'justify'}}>Children with neurodevelopmental disorders such as autism spectrum disorder (ASD) are at a far higher risk for developing anxiety disorders. As a result, many children with ASD perform poorly in school, and many others refuse to participate in the school system and ultimately disengage from formal learning. It is unconscionable that young people with ASD can perform poorly in school or even lose access to formal education entirely due to a condition they cannot control. Given the prevalence of anxiety as a chronic mental disorder and its role as a major obstacle for education for children with ASD, we aim to improve educational accessibility for students with autism and develop classroom-based interventions that can identify anxious states and allow for tailored and targeted anxiety mitigation. 
</Paragraph>

                </Card>
            </div>
          
            <img src={squiggle} style={{ width: "100%", position: 'absolute', transform: "scaleX(-1.2)", bottom: "-2px", fill: "red"}}></img>

        </div>
    )
}

export default Mission